@import "~@kaizen/design-tokens/sass/layout";
@import "~@kaizen/design-tokens/sass/spacing";

$layout-breakpoints-small: 380px;

.head {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $layout-breakpoints-large) {
    &:not(.central) {
      margin-left: -360px;
    }
  }

  .logo {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-md;

    a {
      display: inline-block;
    }

    @media (min-width: $layout-breakpoints-medium) {
      margin-top: $spacing-xxl;
    }
  }

  .welcome {
    @media (min-width: $layout-breakpoints-medium) {
      text-align: center;
    }

    @media (min-width: $layout-breakpoints-large) {
      margin-bottom: 0;
    }

    h1 {
      text-align: center;
      margin: 0;
      font-size: 24px;
      line-height: 34px;

      @media (min-width: $layout-breakpoints-small) {
        font-size: 36px;
        line-height: 46px;
      }

      @media (min-width: $layout-breakpoints-medium) {
        font-size: 72px;
        line-height: 84px;
      }

      @media (min-width: $layout-breakpoints-large) {
        font-size: 64px;
        line-height: 78px;
      }

      @media (min-width: $layout-content-max-width) {
        font-size: 72px;
        line-height: 84px;
      }
    }
  }

  &.central .welcome {
    display: none;
  }
}
