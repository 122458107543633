@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/layout";

.illustration {
  margin-top: 40px;
  z-index: -1;
  transform: scale(1.3);

  @media (min-width: $layout-breakpoints-medium) {
    transform: scale(1.3);
    margin-top: 60px;
  }

  @media (min-width: $layout-breakpoints-large) {
    transform: scale(1.4);
    margin-top: 120px;
  }
}

.main {
  color: rgba($color-purple-800-rgb, 0.7);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $layout-breakpoints-large) {
    &:not(.central) {
      flex-direction: row;
      overflow: visible;
    }

    align-items: flex-start;
  }

  &.central .illustration {
    display: none;
  }
}

.form {
  width: 100%;
  margin-top: -10px;
  max-width: 436px;

  @media (min-width: $layout-breakpoints-medium) {
    max-width: 460px;

    .head:not(.central) & {
      margin-top: -50px;
    }
  }

  @media (min-width: $layout-breakpoints-large) {
    margin-top: 0;
    min-width: 460px;
  }
}

.illustrationImage {
  width: 100%;
  height: auto;
}
