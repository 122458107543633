@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/layout";
@import "~@kaizen/design-tokens/sass/spacing";

.foot {
  text-align: center;
  padding: $spacing-md;
  bottom: 0;
  width: 100%;

  p {
    color: rgba($color-purple-800-rgb, 0.7);

    .link {
      color: $color-blue-500;
      text-decoration: none;
    }
  }

  @media (min-width: $layout-breakpoints-large) {
    text-align: right;

    &.central {
      text-align: center;
    }
  }
}
