@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/layout";
@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/typography";

.form {
  margin: $spacing-sm;

  @media (min-width: $layout-breakpoints-medium) {
    margin: $spacing-md;
  }

  .topLink {
    color: $color-blue-500;
    text-decoration: none;
    font-family: $typography-paragraph-body-font-family;
  }

  .heading {
    margin: $spacing-6 0;
  }

  .subheading {
    margin-top: 0;
    margin-bottom: $spacing-24;
  }

  .bottomLink {
    color: $color-blue-500;
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-family: $typography-paragraph-body-font-family;
  }

  ul {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: $spacing-24;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;

    .lightIcon {
      margin-right: $spacing-12;
      opacity: 0.7;
    }

    .greenIcon {
      margin-right: $spacing-12;
      opacity: 1;
      color: $color-green-600;
    }

    .grayText {
      opacity: 0.7;
    }

    .greenText {
      color: $color-green-600;
      opacity: 1;
    }
  }

  dl,
  dt,
  dd {
    font-family: $typography-paragraph-body-font-family;
  }

  dl {
    margin: 0;
    padding: 0;
  }

  dt {
    font-size: $typography-paragraph-small-font-size;
    line-height: $typography-data-small-line-height;
    letter-spacing: $typography-data-small-letter-spacing;
    font-weight: $typography-data-small-font-weight;
  }

  dd {
    margin: 0 0 1rem;

    p {
      color: rgba($color-purple-800-rgb, 0.7);
    }
  }
}
