.content {
  margin: 0;
  max-width: 1530px;
  z-index: 0;
}

.background {
  height: 100%;
  background-image: url("../Image/password-reset-background.svg");
  background-size: cover;
}

.form {
  width: 460px;
  margin-left: auto;
  margin-right: auto;
}
